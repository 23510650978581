<template>
  <div>
    <helloWorld />
    <div class="content-720 order-box" v-loading="loading">
      <div class="order-info" style="border:1px solid #eee;border-bittom:none">
        <div class="merchant-info-wrapper">
          <div>购买商品：{{detail.colName}}</div>
          <!-- <div style="margin-left:10px">订单号：{{detail.assetCollect.colName}}</div> -->
        </div>
        <div class="right-amount">订单总额：<span style="color:#f00;font-size:26px">￥{{detail.price}}</span></div>
      </div>
      <div style="text-align:center;border:1px solid #eee;padding-bottom:30px">
        <div>
          <img @click="toWx" v-if="wxFalse" class="zf-btn" src="@/assets/WxpayN.png" alt="">
          <img v-if="wxTrue" class="zf-btn" src="@/assets/WxpayS.png" alt="">
          <!-- <img @click="toZfb" v-if="zfbFalse" class="zf-btn" src="@/assets/ZfbpayN.png" alt="">
          <img v-if="zfbTrue" class="zf-btn" src="@/assets/ZfbpayS.png" alt=""> -->
        </div>
        <!-- <div style="display:inline-block">
          <div class="tip"><img src="@/assets/saomiao.svg" alt="">微信支付</div>
        </div> -->
        <div style="font-size:12px;color:#666;margin:40px 0 10px 0" class="pay-time">扫描下方二维码支付<span v-if="timeHtml!=''">（请在<a v-html="timeHtml"></a>内完成支付）</span></div>
        <div class="qrcode-item-wrapper">
          <div class="box-shadow" v-if="refreshVal == true">
            <div style="font-size:14px;margin-top:40px;margin-bottom:10px">二维码已失效</div>
            <el-button size="small" type="primary" icon="el-icon-refresh" @click="refresh">点击刷新</el-button>
            <!-- <img src="@/assets/shuaxin.svg" alt=""> -->
          </div>
          <img style="width:150px" :src="'data:image/png;base64,'+imgUrl" alt="">
          <!-- <VueQr v-if="imgUrl" :margin='10' :size='150' :whiteMargin="true" :logoMargin="3" :logoCornerRadius="20" :text='imgUrl'> </VueQr> -->
        </div>
      </div>
    </div>
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  :close-on-press-escape="false"
  :close-on-click-modal	="false"
  :show-close	="false"
  width="30%">
  <span>该订单支付超时，交易已关闭。</span>
  <span slot="footer" class="dialog-footer">
    <el-button size="small" type="primary" @click="repay">确 定</el-button>
  </span>
</el-dialog>

  </div>
</template>
<script>
// import VueQr from 'vue-qr'
import helloWorld from '@/components/tabBar'
import {payCollect, toPaid, getNotify} from '../../api/index'
import { mapGetters } from 'vuex'

export default{
  components: {
    helloWorld,
    // VueQr
  },
  data() {
    return {
      refreshVal: false,
      loading: true,
      imgUrl: '',
      orderInfo: {},
      detail: {},
      wxFalse: false,
      wxTrue: true,
      zfbFalse: true,
      zfbTrue: false,
      timeHtml:"",
      setTime: null,
      timer1: null,
      dialogVisible: false
    }
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
    this.orderInfo = JSON.parse(this.$route.query.str)
    this.getForm()
    this.getPay()
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.timer1)
    clearInterval(this.setTime);
    next()
  },
  methods: {
    refresh() {
      this.getPay()
    },
    repay() {
      this.dialogVisible = false
      this.$router.push('./')
    },
    toZfb() {
      this.zfbTrue = true
      this.zfbFalse = false
      this.wxFalse = true
      this.wxTrue = false
    },
    toWx() {
      this.zfbTrue = false
      this.zfbFalse = true
      this.wxFalse = false
      this.wxTrue = true
    },
    getForm() {
      let obj = {
        orderCode: this.orderInfo.orderCode
      }
      toPaid(obj).then(res => {
        if (res.code == 0) {
          this.detail = res.data.assetCollect
          // let this_ = this
          // this.setTime = setInterval(function () {
          //   this_.show()
          // }, 1000)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    show(getData) {
      this.loading = false
      // /指定时间
      var nowTime = new Date(getData).getTime()
      //当前时间
      var time = new Date().getTime();
      if(nowTime>time){
        this.timeHtml='清除定时器,传入时间不能大于当前时间'
        clearInterval(this.setTime);
        return false
      }
      //时长 15分钟
      var duration = 15 * 60 * 1000
      //已过时长  毫秒
      var orderDuration = Math.floor(time - nowTime)

      // 如果已过时常小于15分钟
      if (duration >= orderDuration) {
         //距离分
        var dayTimeM = Math.floor((duration - orderDuration) / (1000 * 60) % 60)
        //距离秒
        var dayTimeS = Math.floor((duration - orderDuration) / 1000 % 60)
        this.timeHtml=dayTimeM+'分'+dayTimeS+'秒'

        //  orderDuration
      } else {
        //清除定时器
        this.timeHtml=''
        this.refreshVal = true
        clearInterval(this.setTime);
        // this.dialogVisible = true
        return false
      }
    },
    getPay() {
      let obj = {
        orderCode: this.orderInfo.orderCode
      }
      payCollect(obj).then(res => {
        if (res.code == 0) {
           this.imgUrl = res.data.qrCode
           this.refreshVal = false
            this.getStatus()
            let this_ = this
            this.timer1 = setInterval(() => {
              this_.getStatus()
            }, 5000);
            this.setTime = setInterval(function () {
              this_.show(res.data.createdTime)
            }, 1000)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getStatus() {
      let obj = {
        orderCode: this.orderInfo.orderCode
      }
      getNotify(obj).then(res => {
        if (res.data.orderStatus !=1) {
          clearInterval(this.timer1)
          this.$message.success('支付成功')
          this.$router.push('./radicalDreamer?type=success')
        }
      })
    }
  }
}
</script>
<style scoped>
.box-shadow {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 154px;
}
.box-shadow img {
  width: 30px;
}
.pay-time a{
  font-size: 20px !important;
  color: #f00 !important;
  letter-spacing: 1px;
}
.zf-btn {
  width: 133px;
  /* margin-right: 20px; */
  margin-top: 50px;
}
.content-720 {
  max-width: 720px;
  margin: auto;
  padding: 50px 0;
}
.order-info {
    padding: 20px 30px;
    background-color: #f9f9f9;
    border-top: #409EFF solid 3px;
    border-bottom: #dfdfdf dotted 3px;
    color: #878787;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.merchant-info-wrapper {
  display: flex;
  align-items: center;
}
.tip {
    margin-bottom: 30px;
    text-align: center;
    margin-top: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
}
.tip img {
  margin-right: 6px;
}
.qrcode-item-wrapper {
  /* margin-top: 40px; */
    position: relative;
    /* left: 50%; */
    margin: auto;
    /* transform: translateX(-50%); */
    /* padding: 15px 0; */
    width: 150px;
    /* height: 120px; */
    /* background: linear-gradient(#333, #333) left top, linear-gradient(#333, #333) left top, linear-gradient(#333, #333) right top, linear-gradient(#333, #333) right top, linear-gradient(#333, #333) right bottom, linear-gradient(#333, #333) right bottom, linear-gradient(#333, #333) left bottom, linear-gradient(#333, #333) left bottom; */
    background-repeat: no-repeat;
    background-size: 2px 20px, 10px 2px;
    border: 1px solid #D8D8D8;
}
.right-amount {
  display: flex;
  align-items: center;
}
</style>
